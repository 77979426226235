<template>
  <main class="forgot-password-container">
    <div class="content-wrapper" v-if="!status">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/1cc9db6ba57e8517a575aa02e3ceff6a1c08e9226a26e9359787afc28fdf1680?placeholderIfAbsent=true&apiKey=411e2ec61bdb45ecb3af3338badbc5df"
        alt="Nexlaw logo"
        class="logo"
      />
      <h1 class="title">Forgot Password?</h1>
      <p class="instructions">No worries, we'll send you reset instructions</p>
      <div class="reset-form">
        <div class="input-group">
          <label for="email" class="input-label">Email</label>
          <input
            type="email"
            autocorrect="off"
            autocomplete="off"
            name="email"
            v-validate="'required'"
            placeholder="Email"
            v-model="email"
            aria-label="Email"
            aria-describedby="Email"
            @keyup.enter="forget"
            class="email-input"
            required
          />
          <span class="text-danger text-sm" v-show="errors.has('email')">{{
            errors.first("email")
          }}</span>
        </div>
        <button type="submit" class="reset-button" @click="forget">
          Reset password
        </button>
      </div>
      <button @click="goBackToLogin" class="back-button">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/c918458b20ad03756083c6511666c6b6b2af2db54a6ef46caac6620a04902543?placeholderIfAbsent=true&apiKey=411e2ec61bdb45ecb3af3338badbc5df"
          alt=""
          class="back-icon"
        />
        <span class="back-text">Back to log in</span>
      </button>
    </div>
    <div class="content-wrapper" v-else>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/1cc9db6ba57e8517a575aa02e3ceff6a1c08e9226a26e9359787afc28fdf1680?placeholderIfAbsent=true&apiKey=411e2ec61bdb45ecb3af3338badbc5df"
        alt="Email sent confirmation icon"
        class="confirmation-icon"
      />
      <h1 class="title">Check your email</h1>
      <p class="description">We sent a password reset link to:</p>
      <p class="email">{{ email }}</p>

      <!-- Updated the button to open email app -->
      <button class="primary-button" @click="openEmailApp">
        Open email app
      </button>

      <p class="resend-prompt">
        Didn't receive the email?
        <button @click="forget" class="resend-link">Click to resend</button>
      </p>
      <button class="secondary-button">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/f1df1508e9bd3735906cf7486bc1d3bd0478e2608c8d115bd5c88f08e479051a?placeholderIfAbsent=true&apiKey=411e2ec61bdb45ecb3af3338badbc5df"
          alt=""
          class="back-icon"
        />
        <span @click="goBackToLogin" class="button-text">Back to log in</span>
      </button>
    </div>
  </main>
</template>

<script>
/* eslint-disable */
import Auth from "@/store/Auth.js";

export default {
  data() {
    return {
      email: null,
      errorMessage: "",
      status: false,
    };
  },

  mounted() {
    const storedEmail = sessionStorage.getItem("email");
    const storedStatus = sessionStorage.getItem("status");
    if (storedEmail) {
      this.email = storedEmail;
    }
    if (storedStatus === "true") {
      this.status = true;
    }
  },

  methods: {
    validEmail() {
      const re =
        /^(([^<>()[$$\\.,;:\s@"]+(\.[^<>()[$$\\.,;:\s@"]+)*)|(".+"))@(($$[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}$$)|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.email);
    },

    extractDomain(email) {
      const match = email.match(/@([a-zA-Z0-9._%+-]+\.[a-zA-Z]{2,})/);
      return match ? match[1] : null;
    },

    openEmailApp() {
      const domain = this.extractDomain(this.email);
      if (domain) {
        let emailUrl;
        switch (domain) {
          case "gmail.com":
            emailUrl = `https://mail.google.com`;
            break;
          case "yahoo.co.id":
            emailUrl = `https://mail.yahoo.co.id`;
            break;
          default:
            emailUrl = `mailto:${this.email}`;
            break;
        }

        window.open(emailUrl, "_blank");
      } else {
        console.error("Invalid email domain");
      }
    },

    forget() {
      if (!this.email) {
        this.errorMessage = "Email required.";
        return;
      } else if (!this.validEmail()) {
        this.errorMessage = "Invalid email format";
        return;
      }

      try {
        const res = Auth.forgetPassword({
          email: this.email,
        });

        if (res) {
          this.$toast.success("Success! Please Check Your Email");
          this.status = true;

          sessionStorage.setItem("email", this.email);
          sessionStorage.setItem("status", this.status);

          setTimeout(() => {
            sessionStorage.removeItem("email");
            sessionStorage.removeItem("status");
          }, 120000);
        }
      } catch (error) {
        this.$toast.error(error.message);
      }
    },

    goBackToLogin() {
      this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style scoped>
.forgot-password-container {
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Poppins, sans-serif;
  padding: 120px 80px;
}

.content-wrapper {
  display: flex;
  width: 500px;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
}

.logo {
  width: 88px;
  aspect-ratio: 0.7;
  object-fit: contain;
}

.title {
  color: #383a3e;
  font-size: 24px !important;
  font-weight: 600;
  margin-top: 20px;
}

.instructions {
  color: #86888d;
  font-size: 14px;
  line-height: 24px;
  margin-top: 8px;
  text-align: center;
}

.reset-form {
  width: 100%;
  margin-top: 24px;
}

.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.input-label {
  color: #86888d;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
}

.email-input {
  border-radius: 8px;
  background: #fafbfc;
  padding: 16px 20px;
  font-size: 16px;
  border: 1px solid #e0e0e0;
}

.reset-button {
  width: 100%;
  border-radius: 4px;
  background: #0e4485;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 16px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.reset-button:hover {
  background-color: #0c3a6f;
}

.back-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  background: none;
  border: none;
  color: #0e4485;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  padding: 8px;
  transition: opacity 0.3s;
}

.back-button:hover {
  opacity: 0.8;
}

.back-icon {
  width: 18px;
  height: 18px;
  margin-right: 4px;
}

@media (max-width: 991px) {
  .forgot-password-container {
    padding: 100px 20px;
  }
}

.content-wrapper {
  display: flex;
  width: 500px;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
}

.confirmation-icon {
  aspect-ratio: 0.7;
  object-fit: contain;
  object-position: center;
  width: 88px;
}

.title {
  color: var(--Neutral-Black, #383a3e);
  font-size: 24px;
  font-weight: 600;
  margin-top: 20px;
}

.description {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-weight: 400;
  line-height: 24px;
  margin-top: 8px;
}

.email {
  color: var(--Status-Blue, #1890ff);
  line-height: 24px;
  text-decoration-line: underline;
  margin-top: 8px;
}

.primary-button {
  align-self: stretch;
  border-radius: 4px;
  background: var(--Primary-Blue, #0e4485);
  margin-top: 32px;
  font-size: 16px;
  color: var(--Neutral-White, #fff);
  padding: 8px 16px;
  border: none;
  cursor: pointer;
}

.resend-prompt {
  color: #0e4485;
  font-size: 16px;
  font-weight: 300;
  margin-top: 16px;
}

.resend-link {
  font-weight: 500;
  text-decoration: underline;
  color: rgba(14, 68, 133, 1);
}

.secondary-button {
  border-radius: 4px;
  display: flex;
  margin-top: 32px;

  max-width: 100%;
  align-items: center;
  gap: 4px;
  color: var(--Primary-Blue, #0e4485);
  padding: 2px 8px;
  background: transparent;
  border: none;
  cursor: pointer;
}

.back-icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 18px;
}

.button-text {
  align-self: stretch;
}

@media (max-width: 991px) {
  .forgot-password-email-sent {
    padding: 100px 20px;
  }
}
</style>
